// libs
import { MESSAGES } from '@/constant/messages'
import { validate } from '@/constant/validate'
import * as yup from 'yup'

export const noSchema = yup.object().shape({}).required()

export const schemaLogin = yup
    .object()
    .shape({
        email: yup.string().email(validate.EMAIL_INVALID).required(validate.EMAIL_REQUIRED),
        password: yup.string().required(validate.PASSWORD_REQUIRED),
    })
    .required()

export const schemaForgotPassword = yup
    .object()
    .shape({
        email: yup.string().email(validate.EMAIL_INVALID).required(validate.EMAIL_REQUIRED),
    })
    .required()

export const schemaResetPassword = yup
    .object()
    .shape({
        code: yup.string().required(validate.CODE_REQUIRED),
        password: yup
            .string()
            .required(validate.PASSWORD_REQUIRED)
            .min(12, validate.PASSWORD_LENGTH)
            .matches(/^(?=.*[a-z])(?=.*[A-Z])/, validate.PASSWORD_CHARACTERS),
    })
    .required()

export const schemaName = yup
    .object()
    .shape({
        name: yup.string().max(128, MESSAGES.MSG_004).matches(/\S/, MESSAGES.MSG_012).required(MESSAGES.MSG_012),
        year: yup.string().required(MESSAGES.MSG_012),
        value: yup
            .string()
            .required(MESSAGES.MSG_012)
            .matches(/^[0-9]{1,39}(.[0-9]{1,20})?$/, MESSAGES.MSG_007),
        unit: yup.string().required(MESSAGES.MSG_012).max(20, MESSAGES.MSG_008).matches(/\S/, MESSAGES.MSG_012),
        emission_factor_table_id: yup.string().required(MESSAGES.MSG_012),
    })
    .required()

export const schemaCreateCompany = yup
    .object()
    .shape({
        name: yup.string().required(validate.CANNOT_BLANK),
        planId: yup.string().required(validate.CANNOT_BLANK),
        startMonth: yup.string().required(validate.CANNOT_BLANK),
    })
    .required()

export const schemaEditSite = yup.object().shape({
    sites: yup.array().of(
        yup.object().shape({
            name: yup.string().required(validate.CANNOT_BLANK),
        }),
    ),
})

export const schemaEditUser = yup.object().shape({
    users: yup.array().of(
        yup.object().shape({
            email: yup.string().email(validate.EMAIL_INVALID).required(validate.EMAIL_REQUIRED),
            name: yup.string().required(validate.CANNOT_BLANK),
        }),
    ),
})

export const schemaNotification = yup
    .object()
    .shape({
        name: yup.string().required(validate.CANNOT_BLANK),
        url: yup.string().required(validate.CANNOT_BLANK),
    })
    .required()
