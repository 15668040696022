// libs
import React, { ReactNode } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { AnyObjectSchema } from 'yup'
import { CategoryEmissionFactorTable } from '@/openapi'

type TProps = {
    children: ReactNode | ReactNode[]
    validateSchema: AnyObjectSchema
    defaultValues: { [key: string]: string | number | undefined | Array<CategoryEmissionFactorTable | any> } | object
}
/**
 * ReactHookForm
 * @description FormProvider base on ReactHookForm
 * @param validateSchema - Yup validate schema
 * @param children
 * @param defaultValues
 */
export default function ReactHookForm({ children, validateSchema, defaultValues }: TProps) {
    const formProps = useForm({
        resolver: yupResolver(validateSchema),
        mode: 'onChange',
        defaultValues: defaultValues,
    })

    return <FormProvider {...formProps}>{children}</FormProvider>
}
