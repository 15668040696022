import theme from '@/theme'
import { FormLabel, TextField, TextFieldProps } from '@mui/material'
import React from 'react'

const inputConfigure = {
    fontFamily: theme.typography.fontFamily,
    '& legend': { display: 'none' },
    '& fieldset': { top: 0 },
    '& .MuiInputLabel-root': { display: 'none' },
    '& .MuiInputBase-input': { fontSize: '14px' },
    '& input': {
        fontFamily: theme.typography.fontFamily,
    },
    '& .MuiFormHelperText-root ': {
        fontFamily: theme.typography.fontFamily,
    },
}

export default function InputField({
    isRequired,
    widthlabel = 100,
    sx,
    className,
    InputLabelProps,
    label,
    ...props
}: TextFieldProps & { isRequired?: boolean; widthlabel?: number }) {
    return (
        <div>
            {label && (
                <FormLabel className="display-block fw-600 fz-14 mb-10" style={{ width: widthlabel }}>
                    {label}
                    {isRequired && <label className="fz-12 required-text ml-10"></label>}
                </FormLabel>
            )}
            <TextField
                {...props}
                sx={{
                    ...inputConfigure,
                    ...sx,
                }}
                fullWidth
                className={`input-field ${className}`}
                InputLabelProps={{ ...InputLabelProps, shrink: true }}
            />
        </div>
    )
}
