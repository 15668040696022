export const MESSAGES = {
    MSG_001: '半角数字を入力してください。',
    MSG_002: '',
    MSG_003: '1~12と14~37の範囲で半角数字を入力してください。',
    MSG_004: '128文字以内で入力してください。',
    MSG_005: '1~9の範囲で半角数字を入力してください。',
    MSG_006: '1~32の範囲で半角数字を入力してください。',
    MSG_007: '有効数字60桁で、小数点以下20桁まで入力してください。',
    MSG_008: '20数字以内で入力してください。',
    MSG_009: '有効の年で4桁数字を入力してください。',
    MSG_010: '係数の作成に失敗しました。',
    MSG_011: '係数の作成に成功しました。',
    MSG_012: 'この項目は入力必須です。',
    MSG_013: '更新に失敗しました。',
    MSG_014: '更新に成功しました。',
    MSG_015: '',
    MSG_016: '12桁まで入力してください。',
    MSG_017: '小数点以下は3桁まで入力してください。',
}
