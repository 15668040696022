export const validate = {
    EMAIL_INVALID: 'メールが無効です。',
    EMAIL_REQUIRED: 'メールが無効です。',
    PASSWORD_REQUIRED: 'パスワードが必要です。',
    CODE_REQUIRED: 'コードがが必要です。',
    FIELD_REQUIRED: 'この項目は入力必須です。',
    CANNOT_BLANK: '空白にすることはできません',

    PASSWORD_CHARACTERS: '半角英大文字、英小文字をそれぞれ1文字以上含めてください',
    PASSWORD_LENGTH: '12文字以上で入力してください',
    PASSWORD_NO_NUMBERS: '半角数字を1文字以上含めてください',
    PASSWORD_SAME_AS_EMAIL: 'メールアドレスと異なる文字列を入力してください',
}
